'use client'

import { useRegisterModal } from '@/hooks/use-register-modal'
import s from './create-account-banner.module.css'
import { useUser } from '@/hooks/use-user'
import { Button } from '@/components/v3/atoms/button/button'

export const CreateAccountBanner = () => {
  const registerModal = useRegisterModal()
  const { user } = useUser()

  const handleOnClickCreateAccount = () => {
    registerModal.open()
  }

  if (user) return null

  return (
    <div className={s.wrapper}>
      <div className={s.headerWrapper}>
        <div className={s.header}>
          Top trends, dreamy venues, and expert advice.
        </div>
        <div className={s.subHeader}>
          Saved to your dashboard and delivered straight to your{' '}
          <span className={s.decoratedText}>inbox</span>{' '}
        </div>
      </div>
      <Button onClick={handleOnClickCreateAccount} className={s.button}>
        SIGN UP NOW
      </Button>
    </div>
  )
}

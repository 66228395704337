'use client'

import Card from '@/components/v3/atoms/card/card'
import s from './home-recent-articles.module.css'
import { Article } from '@/types/Article'
import { format } from 'date-fns'
import { GoogleAdClient } from '@/components/v3/atoms/google-ad-client/google-ad-client'
import { HOMEPAGE_ADS } from '@/constants/ads-slots'
import { useState } from 'react'

type Props = {
  articles: Article[]
}

export const HomeRecentArticles = ({ articles }: Props) => {
  const firstRowArticles = articles.slice(0, 2)
  const thirdArticle = articles[2]
  const [secondRowArticles, setSecondRowArticles] = useState<Article[]>(
    articles.slice(3)
  )

  return (
    <section>
      <div className={s.wrapper}>
        <div className={s.cardsContainer}>
          {firstRowArticles.map((article) => (
            <Card
              key={article.id}
              header={article.title}
              category={article.subcategory || article.category!}
              imageUrl={article.hero_image_ref.url}
              imageAlt={article.hero_image_ref.alt}
              itemId={article.id}
              path={article.path}
              type="Article"
              date={format(article.published_date!, 'dd.MM.yyyy')}
            />
          ))}
          <GoogleAdClient
            onAdsLoaded={() => {
              setSecondRowArticles(articles.slice(2))
            }}
            fallback={
              <Card
                key={thirdArticle.id}
                header={thirdArticle.title}
                category={thirdArticle.subcategory || thirdArticle.category!}
                imageUrl={thirdArticle.hero_image_ref.url}
                imageAlt={thirdArticle.hero_image_ref.alt}
                itemId={thirdArticle.id}
                path={thirdArticle.path}
                type="Article"
                date={format(thirdArticle.published_date!, 'dd.MM.yyyy')}
              />
            }
            mobileAd={{
              ...HOMEPAGE_ADS.banner.mobile,
              divProps: {
                style: {
                  width: `${HOMEPAGE_ADS.banner.mobile.size[0]}px`,
                  height: `${HOMEPAGE_ADS.banner.mobile.size[1]}px`,
                  margin: '0 auto',
                },
              },
            }}
            desktopAd={{
              ...HOMEPAGE_ADS.banner.desktop,
              divProps: {
                style: {
                  width: `${HOMEPAGE_ADS.banner.desktop.size[0]}px`,
                  height: `${HOMEPAGE_ADS.banner.desktop.size[1]}px`,
                  margin: '0 auto',
                },
              },
            }}
          />
        </div>
        <div className={s.cardsContainer}>
          {secondRowArticles.map((article) => (
            <Card
              key={article.id}
              header={article.title}
              category={article.subcategory || article.category!}
              imageUrl={article.hero_image_ref.url}
              imageAlt={article.hero_image_ref.alt}
              itemId={article.id}
              path={article.path}
              type="Article"
              date={format(article.published_date!, 'dd.MM.yyyy')}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

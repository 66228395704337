'use client'

import { UiPlacement } from '@/types/api/ui-placement'
import s from './home-venue-tags-slider.module.css'
import { Slider } from '@/components/v2/atoms/slider/slider'
import { Image } from '@/components/v2/atoms/image/image'
import { CSS_BREAKPOINTS_V2, ROUTES, SLIDER_CLASS_NAME } from '@/constants'
import cx from 'classnames'
import { SectionContainerV3 } from '@/components/v3/atoms/section-container/section-container'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import Link from 'next/link'
import { Button } from '@/components/v3/atoms/button/button'

type Props = {
  tags: UiPlacement[]
}

const animation = { duration: 50000, easing: (t: number) => t }

export const HomeVenueTagsSlider = ({ tags }: Props) => {
  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint?.includes('desktop')

  return (
    <section className={s.container}>
      <SectionContainerV3
        innerProps={{
          className: s.container,
        }}
      >
        <h2 className={s.title}>Recommended Venues</h2>
        <p className={s.description}>
          Browse and book the best wedding venues and suppliers recommended by
          Rock My Wedding.
        </p>
      </SectionContainerV3>

      <Slider
        options={{
          slides: {
            number: tags.length,
            spacing: 16,
            perView: 1.75,
          },
          renderMode: 'precision',
          breakpoints: {
            [`(min-width: ${CSS_BREAKPOINTS_V2.tablet})`]: {
              slides: {
                perView: 3.25,
                spacing: 16,
              },
            },
            [`(min-width: ${CSS_BREAKPOINTS_V2.desktopS})`]: {
              slides: {
                perView: 3.5,
                spacing: 0,
              },
            },
            [`(min-width: ${CSS_BREAKPOINTS_V2.desktopM})`]: {
              slides: {
                perView: 3.65,
              },
            },
            [`(min-width: ${CSS_BREAKPOINTS_V2.desktopL})`]: {
              slides: {
                perView: 4.25,
              },
            },
            [`(min-width: ${CSS_BREAKPOINTS_V2.desktopXXL})`]: {
              slides: {
                perView: 6,
              },
            },
          },
          ...(isDesktop
            ? {
                loop: true,
                drag: false,
                created(s) {
                  s.moveToIdx(5, true, animation)

                  s.container.addEventListener('mouseenter', () => {
                    s.animator.stop()
                  })

                  s.container.addEventListener('mouseleave', () => {
                    s.moveToIdx(s.track.details.abs + 5, true, animation)
                  })
                },
                updated(s) {
                  s.moveToIdx(s.track.details.abs + 5, true, animation)
                },
                animationEnded(s) {
                  s.moveToIdx(s.track.details.abs + 5, true, animation)
                },
              }
            : {}),
        }}
        ignoreDefaultBreakpoints
        {...(isDesktop ? { autoMove: true } : {})}
      >
        {tags
          .toSorted((a, b) => a.sort_order - b.sort_order)
          .map((tag) => {
            const src = tag.metadata?.image?.url
            if (!src) {
              return null
            }
            const alt = tag.metadata?.image?.alt || ''

            return (
              <Link
                key={tag.tag_id}
                className={cx(s.slide, SLIDER_CLASS_NAME)}
                href={`${ROUTES.VENUE.ROOT}?type=${tag.tag_id}`}
              >
                <Image
                  src={src}
                  alt={alt}
                  className={s.tagImage}
                  width={400}
                  height={776}
                />
                <div className={s.tagCtaContainer}>
                  <h3 className={s.tagName}>{tag.tag?.name}</h3>
                  <Button variant="secondary" className={s.tagButton}>
                    EXPLORE
                  </Button>
                </div>
              </Link>
            )
          })}
      </Slider>
    </section>
  )
}

'use client'

import { LargeItemWithArticles } from '@/components/v3/molecules/large-item-with-articles/large-item-with-articles'
import s from './home-video-article-grid.module.css'
import dynamic from 'next/dynamic'
import { Button } from '@/components/v3/atoms/button/button'
import { Article } from '@/types/Article'

const ReactPlayer = dynamic(() => import('react-player/lazy'), {
  ssr: false,
})

type Props = {
  articles: Article[]
}

const VIDEO_URL = 'https://vimeo.com/1063535384/bb71210173'

export const HomeVideoArticleGrid = ({ articles }: Props) => {
  return (
    <section>
      <div className={s.header}>
        <h2 className={s.title}>Real Wedding Inspiration</h2>
        <p className={s.description}>
          Draw inspiration from our real weddings featuring stunning venues,
          unique decor, chic fashion, and meaningful personal touches.
        </p>
      </div>

      <LargeItemWithArticles
        mainItem={
          <div className={s.videoContainer}>
            <div className={s.videoHeader}>
              <p className={s.videoTitle}>Get Inspired</p>
              <Button
                className={s.videoButton}
                href="/wedding-inspiration/real-weddings"
              >
                Explore Real Weddings
              </Button>
            </div>
            <div className={s.video}>
              <ReactPlayer
                url={VIDEO_URL}
                width="100%"
                height="100%"
                playing
                loop
                volume={0}
                muted
                playbackRate={0.5}
                controls={false}
                playsinline
              />
            </div>
          </div>
        }
        articles={articles}
        sliderEnabled
        articleProps={{
          primaryColor: '#fff',
        }}
      />
    </section>
  )
}

'use client'

import { Slider } from '@/components/v2/atoms/slider/slider'
import { Article } from '@/types/Article'
import Card, { CardProps } from '@/components/v3/atoms/card/card'
import { format } from 'date-fns'

type Props = {
  articles?: Article[]
  cardProps?: Partial<CardProps>
}

export const CardSlider = ({ articles, cardProps }: Props) => {
  if (!articles?.length) {
    return null
  }

  return (
    <Slider
      ignoreDefaultBreakpoints
      options={{
        slides: { perView: 'auto', spacing: 16, origin: 0 },
      }}
    >
      {articles.map((article) => {
        return (
          <Card
            imageUrl={article.hero_image_ref.url.replace(/\s/g, '')}
            imageAlt={article.hero_image_ref.alt}
            path={article.path}
            header={article.title}
            date={format(article.published_date!, 'dd.MM.yyyy')}
            itemId={article.id}
            category={
              article.article_subcategories?.name ||
              article.article_categories?.name ||
              ''
            }
            type={'Article'}
            size="Small"
            key={article.id}
            adjustToSlider
            {...cardProps}
          />
        )
      })}
    </Slider>
  )
}

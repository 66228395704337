import { format } from 'date-fns'
import { CardSlider } from '@/components/v2/molecules/custom-slider/card-slider/card-slider'
import { Article } from '@/types/Article'
import s from './large-item-with-articles.module.css'
import Card, { CardProps } from '../../atoms/card/card'
import cx from 'classnames'
import { VisibleOn } from '@/components/v3/atoms/visible-on/visible-on'

type Props = {
  mainItem: React.ReactNode
  articles: Article[]
  articleProps?: Partial<CardProps>
  sliderEnabled?: boolean
}

export const LargeItemWithArticles = ({
  articles,
  sliderEnabled,
  mainItem,
  articleProps,
}: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.mainCardWrapper}>{mainItem}</div>

      {sliderEnabled && (
        <VisibleOn mobile tablet>
          <div className={s.sliderContainer}>
            <CardSlider articles={articles} cardProps={articleProps} />
          </div>
        </VisibleOn>
      )}

      <VisibleOn
        className={s.cardsMediaWrapper}
        desktop
        mobile={!sliderEnabled}
        tablet={!sliderEnabled}
      >
        <div
          className={cx(s.cardsWrapper, {
            [s.withoutSlider]: !sliderEnabled,
          })}
        >
          {articles.map((article) => (
            <Card
              imageUrl={article.hero_image_ref.url}
              imageAlt={article.hero_image_ref.alt}
              path={article.path}
              header={article.title}
              date={format(article.published_date!, 'dd.MM.yyyy')}
              itemId={article.id}
              category={
                article.article_subcategories?.name ||
                article.article_categories?.name ||
                ''
              }
              type="Article"
              key={article.id}
              {...articleProps}
            />
          ))}
        </div>
      </VisibleOn>
    </div>
  )
}
